import { Ref, ComputedRef } from 'vue';
import {
  Order,
  UsedStorageZone,
  OrderBag,
  UsedStorageZoneResponse,
} from '@/features/orders';
import { DeliveryUnit } from '@/features/delivery-unit';

export interface UseOrderStorageZones {
  order: Ref<Order | undefined>;
  loadOrder: (id: string) => Promise<void>;
  usedStorageZones: Ref<UsedStorageZone[] | undefined>;
  deliveryUnits: Ref<DeliveryUnit[] | undefined>;
}

export interface UseBagsOrder {
  order: Ref<Order | undefined>;
  loadOrder: (id: string) => Promise<void>;
  bags: ComputedRef<Record<string, OrderBag>>;
}

export interface UseStorageZonesStorage {
  saveStorageZonesQuantityToStorage: (
    bagsArray: UsedStorageZone[] | undefined,
  ) => void;
  getStorageZonesQuantityFromStorage: () => UsedStorageZoneResponse[] | null;
}

export interface UseBagsStorage {
  saveBagsQuantityToStorage: (bagsArray: OrderBag[] | undefined) => void;
  getBagsQuantityFromStorage: () => OrderBag[] | null;
}

export enum BagQrVariables {
  PickupCode = 'pickupCode',
  StorageZoneId = 'storageZoneId',
  LabelNumber = 'labelNumber',
}

export const LABEL_FOR_MANUALLY_SCAN = 'NO-LOCATION';
export const DEFAULT_MANUALLY_SCAN = 'default barcode';
